<template>
  <div class="register">
    <!--    手机验证弹窗-->
    <div class="mobile-test creat-dialog">
      <el-dialog
        v-model="mobileTestVisible"
        title="验证手机"
        :close-on-click-modal="false"
        width="35%"
        :before-close="verifyMobile"
      >
        <div class="dialog-content">
          <div class="test-tip">
            <svg-icon icon-class="warning" class="svg" />
            验证码已发送到您的手机，10分钟内输入有效，请勿泄露。
          </div>
          <el-form
            ref="applyBillForm"
            :model="mobileForm"
            :rules="mobileFormRules"
            label-width="180px"
            label-position="right"
          >
            <el-form-item label="手机号码" style="margin-bottom: 20px;">
              {{ registerForm.mobile }}
            </el-form-item>
            <el-form-item ref="mobileSmsCode" label="验证码" prop="mobileCode" style="margin-bottom: 20px;">
              <div class="phone-code-box">
                <el-input v-model="mobileForm.mobileCode" placeholder="请输入验证码" @focus="smsFocus" @blur="smsBlur" />
                <el-button class="send" :disabled="num !== ''" :loading="sendLoading" @click="send">
                  重发{{ num ? '(' + num + ')' : '' }}
                </el-button>
              </div>
              <div style="color: #2a9c3d;font-size: 12px;line-height: 1;margin-top: 6px;">
                请输入您的手机收到的验证码
              </div>
            </el-form-item>
          </el-form>
          <div style="text-align: center;">
            <el-button style="width: 80px;" type="primary" :loading="submitLoading" @click="submit">提交</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="register-box">
      <el-card class="box-card" :body-style="{width: '800px',height: '670px',padding: '32px 0 0 0'}">
        <div class="register-form">
          <div class="title">
            欢迎注册云桌面系统账号
          </div>
          <div style="margin-bottom: 32px;">
            已有云桌面系统账号？请
            <el-button type="text" @click="login">登录</el-button>
          </div>
          <div class="form">
            <el-form ref="registerForm" :model="registerForm" :rules="registerRules">
              <el-form-item label="" prop="account">
                <el-input v-model="registerForm.account" placeholder="请输入账号名称" @focus="accountFocus" @blur="accountBlur" />
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input ref="passwordRef" v-model="registerForm.password" :type="passwordType" autocomplete="new-password" placeholder="请设置登录密码" @focus="pwdFocus" @blur="pwdBlur">
                  <template #suffix>
                    <svg-icon style="cursor: pointer;margin-right: 8px;" class="svg" :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" @click="showPwd" />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="checkPassword">
                <el-input ref="rePasswordRef" v-model="registerForm.checkPassword" :type="rePasswordType" autocomplete="new-password" placeholder="请再次输入登录密码">
                  <template #suffix>
                    <svg-icon style="cursor: pointer;margin-right: 8px;" class="svg" :icon-class="rePasswordType === 'password' ? 'eye' : 'eye-open'" @click="reShowPwd" />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="mobile">
                <el-input v-model="registerForm.mobile" placeholder="请输入手机号" @focus="mobileFocus" @blur="mobileBlur">
                  <!-- <span slot="prepend">+86</span> -->
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="pmcode">
                <el-input v-model="registerForm.pmcode" placeholder="请输入邀请码" @focus="mobileFocus" @blur="mobileBlur">
                  <!-- <span slot="prepend">+86</span> -->
                </el-input>
              </el-form-item>
              <div style="display: flex;margin-bottom: 20px;">
                <el-form-item style="margin-bottom: 0;" label="" prop="code">
                  <el-input v-model="registerForm.code" placeholder="请输入验证码" style="width: 150px;margin-right: 12px;" @focus="codeFocus" @blur="codeBlur" />
                </el-form-item>
                <div style="width: 138px;" @click="getCode">
                  <img v-if="image" class="image" :src="image" style="cursor: pointer;" alt="点击刷新">
                  <el-button v-else type="text">点击刷新</el-button>
                </div>
              </div>
            </el-form>
            <el-button type="primary" style="width: 300px;margin-bottom: 20px;" :loading="agreeSubmitLoading" @click="agreeSubmit">获取短信验证码</el-button>
            <el-checkbox v-model="isAgree">我已阅读并同意<router-link target="_blank" to="/userAgreement">平台用户服务协议</router-link></el-checkbox>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { captcha, register, duplicatecheck, getUserMobileIsExist, getRegisterSms } from '@/api/user'
import validate from '@/utils/baseValidate.js'
import { ElMessage } from 'element-plus'
import { setToken } from '@/utils/auth'
export default {
  name: 'Register',
  data() {
    const validateAccount = (rule, value, callback) => {
      if (this.status || this.comstatus.account) {
        const reg = /^\d*([a-zA-Z-_])+\d*$/
        if (value.trim() === '') {
          callback(new Error('请输入用户名'))
        } else if (value.trim().length < 2 || value.trim().length > 50) {
          callback(new Error('用户名长度应在2-50个字符之间'))
        } else if (!reg.test(value.trim())) {
          callback(new Error('请输入英文数字及_-且不能全是数字'))
        } else {
          duplicatecheck(this.registerForm.account.trim()).then(res => {
            if (res.meta.status === 455) {
              this.accountRepeat = true
            }
            if (this.accountRepeat) {
              callback(new Error(res.meta.msg))
              this.accountRepeat = false
            } else {
              callback()
            }
          })
        }
      } else {
        callback()
      }
    }
    const validateCheckPassword = (rule, value, callback) => {
      if (value.trim() !== this.registerForm.password.trim()) {
        callback(new Error('再次输入密码不一致'))
      } else {
        callback()
      }
    }
    const validateMobile = (rule, value, callback) => {
      const reg = new RegExp('^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$')
      console.log(value, typeof value)
      if (this.status || this.comstatus.mobile) {
        if (!value.trim()) {
          callback(new Error('手机号不能为空'))
        } else if (!reg.test(value.trim())) {
          callback(new Error('请输入正确的手机号'))
        } else {
          getUserMobileIsExist(this.registerForm.mobile.trim()).then(res => {
            if (res.meta.status === 455) {
              this.mobileRepeat = true
            }
            if (this.mobileRepeat) {
              callback(new Error('手机号已注册'))
              this.mobileRepeat = false
            } else {
              callback()
            }
          })
        }
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (this.status || this.comstatus.code) {
        if (!value.trim()) {
          callback(new Error('验证码不能为空'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validateMobileCode = (rule, value, callback) => {
      if (this.smsStatus || this.smsComstatus.mobileCode) {
        if (!value.trim()) {
          callback(new Error('验证码不能为空'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      sendLoading: false,
      agreeSubmitLoading: false,
      submitLoading: false,
      num: '',
      time: null,
      mobileForm: {
        mobileCode: ''
      },
      mobileFormRules: {
        mobileCode: [
          { required: true, validator: validateMobileCode, trigger: 'blur' }
        ]
      },
      accountRepeat: false,
      mobileRepeat: false,
      mobileTestVisible: false,
      isAgree: true,
      registerForm: {
        account: '',
        password: '',
        checkPassword: '',
        mobile: '',
        pmcode: '',
        code: ''
      },
      registerRules: {
        account: [{ required: true, trigger: 'blur', validator: validateAccount }],
        password: [{ required: true, trigger: 'blur', validator: validate.password }],
        checkPassword: [{ required: true, trigger: 'blur', validator: validateCheckPassword }],
        mobile: [{ required: true, trigger: 'blur', validator: validateMobile }],
        // pmcode: [{ required: true, trigger: 'blur', message: '请输入邀请码' }],
        code: [{ required: true, trigger: 'blur', validator: validateCode }]
      },
      status: false,
      smsStatus: false,
      smsComstatus: {
        mobileCode: false
      },
      comstatus: {
        account: false,
        password: false,
        mobile: false,
        code: false
      },
      passwordType: 'password',
      rePasswordType: 'password',
      image: '',
      keys: ''
    }
  },

  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  mounted() {
    this.getCode()
    if (this.$route.query.tgkey !== undefined) {
      this.registerForm.pmcode = this.$route.query.tgkey
    }
  },
  methods: {
    verifyMobile(done) {
      this.num = ''
      clearInterval(this.time)
      done()
      this.getCode()
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
      } else {
        this.passwordType = 'password'
      }
      // this.$nextTick(() => {
      //   this.$refs.passwordRef.focus()
      // })
    },
    reShowPwd() {
      if (this.rePasswordType === 'password') {
        this.rePasswordType = 'text'
      } else {
        this.rePasswordType = 'password'
      }
      // this.$nextTick(() => {
      //   this.$refs.rePasswordRef.focus()
      // })
    },
    // 用户光标聚焦
    accountFocus() {
      this.comstatus.account = false
      this.$refs.registerForm.clearValidate('account')
    },
    // 用户光标离开
    accountBlur() {
      this.status = false
      if (this.registerForm.account !== '') {
        this.comstatus.account = true
      }
    },
    // 用户光标聚焦
    pwdFocus() {
      this.comstatus.password = false
      this.$refs.registerForm.clearValidate('password')
    },
    // 用户光标离开
    pwdBlur() {
      this.status = false
      if (this.registerForm.password !== '') {
        this.comstatus.password = true
      }
    },
    // 用户光标聚焦
    mobileFocus() {
      this.comstatus.mobile = false
      this.$refs.registerForm.clearValidate('mobile')
    },
    // 用户光标离开
    mobileBlur() {
      this.status = false
      if (this.registerForm.mobile !== '') {
        this.comstatus.mobile = true
      }
    },
    codeFocus() {
      this.comstatus.code = false
      this.$refs.registerForm.clearValidate('code')
    },
    // 用户光标离开
    codeBlur() {
      this.status = false
      if (this.registerForm.code !== '') {
        this.comstatus.code = true
      }
    },
    smsFocus() {
      this.smsComstatus.mobileCode = false
      this.$refs.applyBillForm.clearValidate('mobileCode')
    },
    // 用户光标离开
    smsBlur() {
      this.smsStatus = false
      if (this.mobileForm.mobileCode !== '') {
        this.smsComstatus.mobileCode = true
      }
    },
    // 协议跳转
    service() {
      const routeUrl = this.$router.resolve('/personal-info/agreement-service')
      window.open(routeUrl.href, '_blank')
    },
    low() {
      const routeUrl = this.$router.resolve('/personal-info/agreement-low')
      window.open(routeUrl.href, '_blank')
    },
    // isAgreeChange
    // 同意并提交
    agreeSubmit() {
      this.agreeSubmitLoading = true
      this.status = true
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          if (!this.isAgree) {
            ElMessage.error('请勾选同意协议')
            this.agreeSubmitLoading = false
            return
          }
          const data = {
            account: this.registerForm.account.trim(),
            captchaKey: this.keys,
            code: this.registerForm.code.trim(),
            phone: this.registerForm.mobile.trim()
          }
          getRegisterSms(data).then(res => {
            if (res.meta.status === 200) {
              this.mobileTestVisible = true
              this.timing()
              this.$nextTick(() => {
                this.$refs['mobileSmsCode'].resetField()
              })
              setTimeout(() => {
                this.agreeSubmitLoading = false
              }, 350)
              // this.mobileForm.mobileCode = res.data
            } else {
              ElMessage.error(res.meta.msg)
              this.getCode()
              setTimeout(() => {
                this.agreeSubmitLoading = false
              }, 350)
            }
          }).catch(error => {
            console.log(error)
            setTimeout(() => {
              this.agreeSubmitLoading = false
            }, 350)
          })
        } else {
          this.agreeSubmitLoading = false
        }
      })
    },
    // 计时器
    timing() {
      this.num = 60
      this.time = setInterval(() => {
        this.num--
        if (this.num === 0 || this.mobileTestVisible === false) {
          this.num = ''
          clearInterval(this.time)
        }
      }, 1000)
    },
    send() {
      const data = {
        account: this.registerForm.account.trim(),
        captchaKey: this.keys,
        code: this.registerForm.code.trim(),
        phone: this.registerForm.mobile.trim()
      }
      this.sendLoading = true
      getRegisterSms(data).then(res => {
        this.timing()
        if (res.meta.status === 200) {
          setTimeout(() => {
            this.sendLoading = false
          }, 350)
        } else {
          ElMessage.error(res.meta.msg)
          this.sendLoading = false
        }
      }).catch(error => {
        console.log(error)
        setTimeout(() => {
          this.sendLoading = false
        }, 350)
      })
    },
    // 提交注册信息
    submit() {
      this.smsStatus = true
      const data = { account: this.registerForm.account.trim(), code: this.mobileForm.mobileCode.trim(), mobile: this.registerForm.mobile.trim(), password: this.registerForm.password.trim(), projectId: '5726455318996758', pmcode: this.registerForm.pmcode.trim() }
      this.$refs['applyBillForm'].validate(valid => {
        if (valid) {
          this.submitLoading = true
          register(data).then(res => {
            if (res.meta.status === 201) {
              // ElMessage.success('注册成功')
              // setTimeout(() => {
              //   this.submitLoading = false
              // }, 350)
              this.$store.commit('user/SET_TOKEN', res.data)
              setToken(res.data)
              this.$nextTick(() => {
                this.$router.push('/desktop')
              })
            } else {
              ElMessage.error(res.meta.msg)
              setTimeout(() => {
                this.submitLoading = false
              }, 350)
            }
          }).catch(error => {
            console.log(error)
            setTimeout(() => {
              this.submitLoading = false
            }, 350)
          })
        } else {
          return false
        }
      })
    },
    // 获取验证码
    getCode() {
      captcha().then((res) => {
        this.image = res.data.image
        this.keys = res.data.key
      })
    },
    login() {
      this.$router.push('/login')
    },
    register() {

    }
  }
}
</script>

<style lang="less" scoped>
  .register {
    width: 100vw;
    height: 100vh;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    .phone-code-box{
      ::v-deep .el-input{
        width: 60%;
      }
    }
    .mobile-test {
      font-size: 12px;
      .test-tip {
        margin: 20px;
        height: 40px;
        line-height: 40px;
        background: #f9f1da;
        color: #202020;
        .svg {
          margin: 0 10px;
        }
      }
      .send.el-button {
        display: inline-block;
        width: 88px;
        height: 40px;
        /*line-height: 40px;*/
        background: #f2f2f2;
        color: #737d85;
        /*font-size: 12px;*/
        /*text-align: center;*/
        /*cursor: pointer;*/
        margin-left: 12px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .register-box {
      font-size: 12px;
      color: #313a46;
      .register-form {
        text-align: center;
        .title {
          font-size: 24px;
          margin-bottom: 20px;
        }
        .form {
          display: flex;
          flex-direction: column;
          align-items: center;
          .el-input {
            width: 300px;
          }
          ::v-deep .el-input__suffix-inner{
            // line-height: 32px;
            padding-top: 10px;
          }
        }
      }
    }
  }
</style>

<style lang="less">
  .register {
    .register-box {
      .register-form {
        .el-button{
          padding-left: 0;
        }
        .form {
          .el-input__inner {
            border-color: #cfd3e3;
            color: #313a46;
          }
        }
      }
    }
    .mobile-test.creat-dialog .el-dialog {
      width: 600px !important;
      min-width: 0;
    }
  }
</style>

