export default {
  loginAccount: (rule, value, callback) => {
    const reg = /^[A-Za-z0-9-_.@]+$/
    if (value.trim() === '') {
      callback(new Error('请输入账号或者手机号或者邮箱'))
    } else if (value.trim().length < 2 || value.trim().length > 50) {
      callback(new Error('长度应在2-50个字符之间'))
    } else if (!reg.test(value.trim())) {
      callback(new Error('请输入英文数字及_-.@'))
    } else {
      callback()
    }
  },
  account: (rule, value, callback) => {
    const reg = /^[A-Za-z0-9_]+$/
    if (value.trim() === '') {
      callback(new Error('请输入账号'))
    } else if (value.trim().length < 2 || value.trim().length > 50) {
      callback(new Error('账号长度应在2-50个字符之间'))
    } else if (!reg.test(value.trim())) {
      callback(new Error('请输入英文数字及_'))
    } else {
      callback()
    }
  },
  name: (rule, value, callback) => {
    const reg = /^[\u4e00-\u9fa5a-z-_.A-Z0-9]+$/
    if (value.trim() === '') {
      callback(new Error('请输入名称'))
    } else if (value.trim().length < 2 || value.trim().length > 50) {
      callback(new Error('名称长度应在2-50个字符之间'))
    } else if (!reg.test(value.trim())) {
      callback(new Error('请输入中英文数字及_-.'))
    } else {
      callback()
    }
  },
  nickname: (rule, value, callback) => {
    const reg = /^[\u4e00-\u9fa5a-z-_.A-Z0-9]+$/
    if (value.trim() === '') {
      callback(new Error('请输入昵称'))
    } else if (value.trim().length < 2 || value.trim().length > 50) {
      callback(new Error('昵称长度应在2-50个字符之间'))
    } else if (!reg.test(value.trim())) {
      callback(new Error('请输入中英文数字及_-.'))
    } else {
      callback()
    }
  },
  linkName: (rule, value, callback) => {
    const reg = /^[\u4e00-\u9fa5a-z-_.A-Z0-9]+$/
    if (value.trim() === '') {
      callback(new Error('请输入姓名'))
    } else if (value.trim().length < 2 || value.trim().length > 50) {
      callback(new Error('请输入正确的姓名'))
    } else if (!reg.test(value.trim())) {
      callback(new Error('请输入正确的姓名'))
    } else {
      callback()
    }
  },
  password: (rule, value, callback) => {
    const regExp = /([^\s])\s+([^\s\b])/g
    const reg = /[\u4E00-\u9FA5]/g
    if (value.trim() === '') {
      callback(new Error('请输入密码'))
    } else if (value.trim().length < 6 || value.trim().length > 20) {
      callback(new Error('密码长度应在6-20个字符'))
    } else if (regExp.test(value.trim())) {
      callback(new Error('请不要输入空格'))
    } else if (reg.test(value.trim())) {
      callback(new Error('密码不能包含中文'))
    } else {
      callback()
    }
  },
  phone: (rule, value, callback) => {
    if (value.trim() === '') {
      callback(new Error('请输入手机号'))
    } else {
      if (!/^1[3456789]\d{9}$/.test(value.trim())) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
  },
  // /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x
  idCard: (rule, value, callback) => {
    const regExp = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    const reg = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
    if (value.trim() === '') {
      callback(new Error('请输入身份证号码'))
    } else {
      if (!regExp.test(value.trim()) && !reg.test(value.trim())) {
        callback(new Error('请输入正确的身份证号码'))
      } else {
        callback()
      }
    }
  },
  price: (rule, value, callback) => {
    const reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/
    if (value.trim() === '') {
      callback(new Error('请输入充值金额'))
    } else if (!reg.test(value.trim())) {
      callback(new Error('请输入正确的充值金额'))
    } else {
      callback()
    }
  },
  priceV2: (rule, value, callback) => {
    const reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/
    if (value.trim() === '') {
      callback()
    } else if (!reg.test(value.trim())) {
      callback(new Error('请输入正确的充值金额'))
    } else {
      callback()
    }
  },
  // 环境名称
  envName: (rule, value, callback) => {
    const reg = /^[a-z][a-z0-9]*$/
    if (value.trim() === '') {
      callback(new Error('请输入名称'))
    } else if (value.trim().length > 20) {
      callback(new Error('名称长度应在20个字符以内'))
    } else if (!reg.test(value.trim())) {
      callback(new Error('请输入小写字母和数字且只能以字母开头'))
    } else {
      callback()
    }
  },
  // 正整数
  num: (rule, value, callback) => {
    const reg = /^\+?[1-9][0-9]*$/
    if (!reg.test(value.trim())) {
      callback(new Error('请输入正确的数字'))
    } else {
      callback()
    }
  }
}
